<template>
  <div class="payment" :style="{'minHeight':cHeight}">
    <div class="header"><img src="../assets/icon_back.png" alt="" @click="returnPage"><span>对公打款</span></div>
    <div class="tip">
      <div>请于 <span>{{info.lastDate}}</span> 前完成以下汇款</div>
      <div>否则订单将自动取消</div>
    </div>
    <div class="main">
      <div class="border"></div>
      <div class="main_in">
        <div class="info">
          <div class="list">
            <div class="item">
              <div class="left">汇款金额</div>
              <div class="right price"><span>￥</span>{{info.OrderAmount}}</div>
            </div>
            <div class="item">
              <div class="left">收款账户</div>
              <div class="right">{{info.CollectionAccount}}</div>
            </div>
            <div class="item">
              <div class="left">开户银行</div>
              <div class="right">{{info.OpenBank}}</div>
            </div>
            <div class="item">
              <div class="left">银行账号</div>
              <div class="right">
                <div>{{info.BankAccount}}</div>
                <!-- <div class="copy" @click="copy"><img src="../assets/icon_copy.png" alt=""><span>复制信息</span></div> -->
              </div>
            </div>
          </div>
          <van-uploader :after-read="afterRead" :max-count="2">
            <div class="btn">上传支付凭证</div>
          </van-uploader>
        </div>
      </div>
    </div>
    <div class="foot">由无锡中铠信息咨询服务有限公司提供技术支持</div>
    <!-- <input type="text" id="txt" :value="bankinfo" readonly> -->
  </div>
</template>

<script>
  import {
    UploadLvdouCollege0rderFile,
    GetPayProductInfo
  } from '@/api/index'
  export default {
    data() {
      return {
        cHeight: null,
        info: null,
        bankinfo: ''
      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'
      document.title = '支付申请'
      let that = this;
      let id = localStorage.getItem('orderCustomerId')
      GetPayProductInfo({
        orderCustomerId: id
      }).then(res => {
        if (res.state == 0) {
          let list = res.data;
          list.lastDate = list.lastDate.replaceAll('/', '-');
          list.CollectionAccount = '无锡中铠信息咨询服务有限公司'
          list.OpenBank = '招商银行无锡城西支行'
          list.BankAccount = '510904968110301'
          that.bankinfo = list.CollectionAccount + ' ' + list.OpenBank + ' ' + list.BankAccount
          that.info = list
        }
      })
    },
    methods: {
      copy() {
        var element = document.getElementById("txt");
        element.select();
        element.setSelectionRange(0, element.value.length);
        document.execCommand('copy');
        this.$toast('已复制')
      },
      returnPage() {
        window.history.go(-1)
      },
      afterRead(file) {
        let that = this;
        const formData = new FormData();
        let id = localStorage.getItem('orderCustomerId')
        formData.append("file", file.file);
        formData.append("orderCustomerId", id);
        var loading = this.$toast.loading({
          duration: 0,
          forbidClick: true,
        });
        UploadLvdouCollege0rderFile(formData).then(res => {
          loading.clear();
          if (res.state == 0) {
            that.$toast('上传成功')
            var times = setTimeout(() => {
              that.$router.push('/pay_result?type=upload')
              clearTimeout(times)
            }, 1000)
          }
        })
      },
    }
  }

</script>
<style scoped lang='scss'>
  .payment {
    background: #F4F4F4 url('../assets/bg_3.png') no-repeat;
    background-size: 100% 600px;

    .header {
      text-align: center;
      padding-top: 20px;
      font-size: 30px;
      color: #FFFFFF;
      position: relative;
      margin-bottom: 45px;

      img {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 12px;
        left: 22px;
      }
    }

    .tip {
      padding: 0 34px;
      font-size: 26px;
      color: #FFFFFF;
      margin-bottom: 42px;

      span {
        color: #FFC426;
      }
    }

    .main {
      padding: 0 20px;

      .border {
        height: 28px;
        background: #1552FF;
        border-radius: 14px;
      }

      .main_in {
        padding: 0 10px;
      }

      .info {
        background: linear-gradient(180deg, #B3D8FF 1%, #EAF5FF 7.000000000000001%, #FFFFFF 19%);
        border-radius: 0px 0px 16px 16px;
        position: relative;
        top: -12px;
        padding: 70px 30px 72px;

        .list {
          .item {
            position: relative;
            margin-bottom: 33px;

            .left {
              font-size: 28px;
              color: #999999;
            }

            .right {
              position: absolute;
              right: 0;
              top: 0;
              font-size: 28px;
              color: #333333;
              text-align: right;
            }

            .price {
              font-size: 36px;
              font-weight: bold;
              color: #E10D0D;

              span {
                font-size: 28px;
              }
            }

            .copy {
              margin-top: 20px;

              img {
                width: 36px;
                height: 36px;
                vertical-align: middle;
              }

              span {
                font-size: 24px;
                color: #1387FF;
                vertical-align: middle;
              }
            }
          }
        }

        .btn {
          width: 625px;
          height: 80px;
          background: #1387FF;
          border-radius: 40px;
          margin: 150px auto 0;
          font-size: 34px;
          color: #FFFFFF;
          text-align: center;
          line-height: 80px;
        }

      }
    }

    .foot {
      width: 100%;
      text-align: center;
      font-size: 22px;
      color: #999999;
      position: absolute;
      bottom: 36px;
      left: 0;
    }

    #txt {
      position: absolute;
      left: -10000px;
    }
  }

</style>
<style lang='scss'>


</style>
